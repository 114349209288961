import { stripoAuth } from "../envVars";
import { fetchApi } from "./fetch/fetchApi";

interface StripoAuthParams {
  secretKey?: string;
  pluginId?: string;
  [key: string]: any;
}

const getStripoAuthToken = (additionalParams: StripoAuthParams = {}) => {
  return () => {
    const authBody = {
      ...stripoAuth,
      ...additionalParams,
    };

    return fetchApi<string>("https://plugins.stripo.email/api/v1/auth", {
      method: "POST",
      body: JSON.stringify(authBody),
    }).then((data: any) => data.token);
  };
};

export { getStripoAuthToken };
