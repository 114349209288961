import React from "react";
import clsx from "clsx";
import "./StripoView.css";

interface StripoViewProps {
  codeEditorButtonRef: React.RefObject<HTMLButtonElement>;
  historyButtonRef: React.RefObject<HTMLButtonElement>;
  settingsVisible?: boolean;
  historyVisible?: boolean;
  lgUp?: boolean;
}

function StripoView(props: StripoViewProps) {
  const { codeEditorButtonRef, historyButtonRef } = props;

  return (
    <div className="stripo-root">
      <div id="stripoEditorContainer"></div>
      <button
        type="button"
        ref={codeEditorButtonRef}
        className="stripo-codeeditor-button"
        id="stripoCodeEditorButton"
      >
        Code editor
      </button>
      <button
        type="button"
        ref={historyButtonRef}
        className="stripo-history-button"
        id="versionHistoryButtonSelector"
      >
        History
      </button>
    </div>
  );
}

export default React.memo(StripoView);
