import React from "react";
import {
  NavigationChipsItemType,
  TopBarProps,
} from "@/routes/Automations/Editor/types";
import { Box, Button, Chip, Menu, MenuItem, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  CheckBold,
  CloseBig,
  MoreHorizontal,
  ChevronRight,
  ChevronDown,
  ChevronUp,
} from "@tiny/icons";
import OverflowTypographyWithTooltip from "@/components/OverflowTooltip";
import { useScreenLoaderInvokers } from "@tiny/lib";
import StripoSwitcher from "@tiny/stripo-editor/StripoSwitcher";

// TODO parameterize properly and pass context data
const TemplateEditorTopBar = ({
  subject,
  ctaAction,
  cancelAction,
  ctaText,
  navigationChips,
  type = "button",
  ctaId,
  ctaActivateAction,
  startPath,
}: TopBarProps) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { showScreenLoader } = useScreenLoaderInvokers();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState<boolean>(false);
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  React.useEffect(() => {
    setOpen(Boolean(anchorEl));
  }, [anchorEl]);
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: theme.palette.grey[0],
        m: 0,
        py: 3,
        px: 6,
        boxShadow: `inset 0px -0.5px 0px #D4D6E0`, // color not in palette
        position: "relative",
      })}
    >
      <Box
        maxWidth="30%"
        sx={(theme) => ({
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          gap: theme.spacing(2),
        })}
      >
        <Button
          sx={{
            p: 0,
            m: 0,
            minWidth: 0,
          }}
          onClick={() => cancelAction()}
          id="automations-overview-edit-close"
        >
          <CloseBig
            sx={(theme) => ({
              fill: theme.palette.grey[80],
              width: theme.spacing(4),
              height: theme.spacing(4),
            })}
          />
        </Button>
        <Box overflow="hidden" maxWidth="156px">
          <OverflowTypographyWithTooltip
            variant="h4"
            color="grey.100"
            sx={{
              mr: 2,
              alignContent: "center",
            }}
            text={subject}
            value={subject}
          />
        </Box>
        <Chip
          label="Automation"
          size="small"
          variant="filled"
          sx={(theme) => ({
            backgroundColor: "#EBF6EE", // color not in palette
            color: "#36A455", // color not in palette
            typography: theme.typography.p3,
          })}
        />
      </Box>
      <Box
        sx={(theme) => ({
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: theme.spacing(6),
          position: "absolute",
          left: "50%",
          top: "50%",
          translate: "-50% -50%",
        })}
      >
        {navigationChips.map((chip, index) => {
          if (chip.type === NavigationChipsItemType.active) {
            return (
              <Chip
                key={`nav-chip-${index}`}
                label={chip.title}
                size="medium"
                icon={<ChevronRight />}
                variant="filled"
                sx={(theme) => ({
                  color: theme.palette.grey[100],
                  backgroundColor: theme.palette.grey[20],
                  fontWeight: theme.typography.fontWeightMedium,
                  fontSize: theme.spacing(3.5),
                })}
              />
            );
          }
          if (chip.type === NavigationChipsItemType.inactive) {
            return (
              <Chip
                key={`nav-chip-${index}`}
                label={chip.title}
                size="medium"
                icon={<MoreHorizontal />}
                variant="filled"
                sx={(theme) => ({
                  color: theme.palette.grey[80],
                  backgroundColor: "transparent",
                  fontWeight: theme.typography.fontWeightRegular,
                  fontSize: theme.spacing(3.5),
                })}
              />
            );
          }
          if (chip.type === NavigationChipsItemType.complete) {
            return (
              <Chip
                key={`nav-chip-${index}`}
                label={chip.title}
                size="medium"
                icon={
                  <CheckBold
                    sx={(theme) => ({
                      height: theme.spacing(3.5),
                      fill: theme.palette.primary[100],
                    })}
                  />
                }
                variant="filled"
                sx={(theme) => ({
                  color: theme.palette.primary[100],
                  backgroundColor: "transparent",
                  fontWeight: theme.typography.fontWeightRegular,
                  fontSize: theme.spacing(3.5),
                })}
                clickable
                onClick={() => {
                  showScreenLoader();
                  navigate(
                    `../${chip.navigate}${
                      searchParams ? `?${searchParams.toString()}` : ""
                    }`,
                    {
                      state: { startPath },
                    }
                  );
                }}
              />
            );
          }
          return <div />;
        })}
      </Box>
      <Box
        sx={(theme) => ({
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          gap: theme.spacing(2),
        })}
      >
        {ctaActivateAction && <StripoSwitcher />}
        {ctaActivateAction ? (
          <>
            <Button
              sx={{ px: 4, py: { xl: 2.5, sm: 1.5 }, pr: 3, cursor: "pointer" }}
              variant="contained"
              endIcon={open ? <ChevronUp /> : <ChevronDown />}
              onClick={open ? handleCloseMenu : handleOpenMenu}
            >
              Save
            </Button>
            <Menu
              id="automations-overview-edit-save-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleCloseMenu}
              sx={{ mt: 4 }}
              PaperProps={{
                style: {
                  marginTop: -12,
                },
              }}
            >
              <MenuItem
                onClick={() => ctaActivateAction()}
                id="automations-overview-edit-save-activate-design"
              >
                <Typography variant="p2">Save & Activate</Typography>
              </MenuItem>
              <MenuItem onClick={() => ctaAction()} id={ctaId}>
                <Typography variant="p2">{ctaText}</Typography>
              </MenuItem>
            </Menu>
          </>
        ) : (
          <Button
            onClick={() => ctaAction()}
            variant="contained"
            type={type}
            id={ctaId}
          >
            {ctaText}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export { TemplateEditorTopBar };
