import React, { useContext } from "react";
import { styled, useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useStripoContext } from "../StripoContext";
import { Repeat } from "@tiny/icons";
import { hex2rgb } from "@tiny/utils/colors";

const GlowingIconButton = styled(IconButton)(({ theme }) => ({
  "@keyframes glowing": {
    "0%": {
      boxShadow: "0 0 5px #c6e2ff",
    },
    "100%": {
      boxShadow: "0 0 20px #c6e2ff",
    },
  },
  animation: "glowing 1.5s infinite alternate",
}));

const VersionSwitcher = () => {
  const { version, switchVersion } = useStripoContext();
  const theme = useTheme();

  const toggleVersion = () => {
    const newVersion = version === "v1" ? "v2" : "v1";
    switchVersion?.(newVersion);
  };

  const tooltipTitle =
    version === "v1"
      ? "Switch to New Editor (Beta v2)"
      : "Switch to Classic Editor (v1)";

  return (
    <Tooltip
      title={tooltipTitle}
      componentsProps={{
        popper: {
          sx: {
            "& .MuiTooltip-tooltip": {
              padding: 2,
              fontSize: theme.spacing(3),
              backgroundColor: hex2rgb(theme.palette.grey[100], 0.9),
            },
          },
        },
      }}
    >
      <GlowingIconButton
        color="primary"
        onClick={toggleVersion}
        aria-label="Switch editor version"
        size="medium"
      >
        <Repeat />
      </GlowingIconButton>
    </Tooltip>
  );
};

export default VersionSwitcher;
