import { useIsLargerThan } from "@tiny/theme/utils";
import { ForwardedRef } from "react";
import * as React from "react";
import MessageDialog from "../Dialogs/MessageDialog";

import { useStripoContext } from "../StripoContext";

import {
  AmpErrorAction,
  CreateVersionHistoryPointAsyncCallback,
  GetTemplateCallback,
  StripoInstance,
  StripoProps,
} from "../Stripo.types";
import { createStripoScript, initStripo } from "./Stripo.helpers";
import { StripoView } from "./StripoView";

function Stripo(props: StripoProps, ref: ForwardedRef<StripoInstance>) {
  const {
    codeEditorVisible,
    css,
    emailId,
    historyVisible,
    html,
    onHistoryHide,
    onTemplateChange,
    onTemplateLoaded,
    onTemplateUnloaded,
    viewOnly,
    settingsVisible,
  } = props;

  const lgUp = useIsLargerThan("lg");

  const [lgUpInitial] = React.useState(lgUp);

  const [messageOpen, setMessageOpen] = React.useState(false);

  const [errorMessage, setErrorMessage] = React.useState<string>();

  const { accountId, userFullName, getAuthToken } = useStripoContext();

  const [loaded, setLoaded] = React.useState(false);

  const [inited, setInited] = React.useState(false);

  const historyLoadRef = React.useRef(false);

  const codeEditorButtonRef = React.useRef<HTMLButtonElement>(null);

  const historyButtonRef = React.useRef<HTMLButtonElement>(null);

  const shouldCheckChangeRef = React.useRef(true);

  const firstLoadedContentRef = React.useRef<{ html?: string; css?: string }>(
    {}
  );

  const handleMessageClose = React.useCallback(() => {
    setMessageOpen(false);
  }, []);

  const handleToggleCodeEditor = React.useCallback(
    (visible: boolean) => {
      const shouldShow = codeEditorVisible && !visible;
      if (shouldShow) {
        codeEditorButtonRef.current?.click();
      }
    },
    [codeEditorVisible]
  );

  const handleHistoryLoad = React.useCallback(() => {
    historyLoadRef.current = true;
  }, []);

  const handleNotificationError = React.useCallback((message: string) => {
    setErrorMessage(message);
    setMessageOpen(true);
  }, []);

  const handleHistoryHide = React.useCallback(() => {
    if (historyLoadRef.current) {
      historyLoadRef.current = false;
    } else {
      onHistoryHide?.();
    }
  }, [onHistoryHide]);

  const handleTemplateLoaded = React.useCallback(() => {
    onTemplateLoaded?.();
    if (shouldCheckChangeRef.current) {
      // @ts-ignore
      window.StripoApi.getTemplate((html: string, css: string) => {
        firstLoadedContentRef.current = { html, css };
      });
    }
  }, [onTemplateLoaded]);

  const handleTemplateChange = React.useCallback(() => {
    if (shouldCheckChangeRef.current) {
      // @ts-ignore
      window.StripoApi.getTemplate((html: string, css: string) => {
        if (
          firstLoadedContentRef.current.html !== html ||
          firstLoadedContentRef.current.css !== css
        ) {
          onTemplateChange?.();
        }
        shouldCheckChangeRef.current = false;
      });
    } else {
      onTemplateChange?.();
    }
  }, [onTemplateChange]);

  React.useImperativeHandle(ref, () => ({
    getTemplate: (callback: GetTemplateCallback) => {
      // @ts-ignore
      window.StripoApi.getTemplate(callback);
    },
    createVersionHistoryPointAsync: (
      callback?: CreateVersionHistoryPointAsyncCallback
    ) => {
      // @ts-ignore
      window.StripoApi.createVersionHistoryPointAsync(callback);
    },
    reload: () => {
      if (accountId && userFullName) {
        initStripo({
          html,
          css,
          getAuthToken,
          accountId,
          emailId,
          onHistoryLoad: handleHistoryLoad,
          onHistoryHide: handleHistoryHide,
          onTemplateLoaded: handleTemplateLoaded,
          onToggleCodeEditor: handleToggleCodeEditor,
          onTemplateChange: handleTemplateChange,
          onNotificationError: handleNotificationError,
          userFullName,
          viewOnly,
        });
      }
    },
    showAmpErrorsModal: (
      ampErrors: string[],
      userSuccessButtons: AmpErrorAction[],
      onCancel?: () => void,
      onSubmit?: () => void
    ) => {
      // @ts-ignore
      window.StripoApi.showAmpErrorsModal(
        ampErrors,
        userSuccessButtons,
        onCancel,
        onSubmit
      );
    },
  }));

  React.useEffect(() => {
    if (historyVisible) {
      historyButtonRef.current?.click();
    } else {
      const closeLink = document.querySelector<HTMLAnchorElement>(
        ".revision-panel-back"
      );
      closeLink?.click();
    }
  }, [historyVisible]);

  React.useEffect(() => {
    if (!inited && loaded && accountId && userFullName && emailId) {
      setInited(true);
      initStripo({
        html,
        css,
        getAuthToken,
        accountId,
        emailId,
        onHistoryLoad: handleHistoryLoad,
        onHistoryHide: handleHistoryHide,
        onTemplateLoaded: handleTemplateLoaded,
        onToggleCodeEditor: handleToggleCodeEditor,
        onTemplateChange: handleTemplateChange,
        onNotificationError: handleNotificationError,
        userFullName,
        viewOnly,
        codeEditorButtonId: codeEditorVisible
          ? "stripoCodeEditorButton"
          : undefined,
      });
    }
  }, [
    accountId,
    codeEditorVisible,
    css,
    emailId,
    getAuthToken,
    handleHistoryHide,
    handleHistoryLoad,
    handleNotificationError,
    handleTemplateChange,
    handleTemplateLoaded,
    handleToggleCodeEditor,
    html,
    inited,
    loaded,
    onTemplateChange,
    onTemplateLoaded,
    userFullName,
    viewOnly,
  ]);

  React.useEffect(() => {
    return onTemplateUnloaded;
  }, [onTemplateUnloaded]);

  React.useEffect(() => {
    return () => {
      // @ts-ignore
      window.StripoApi?.stop();
    };
  }, []);

  React.useEffect(() => {
    // @ts-ignore
    if (window.StripoApi) {
      setLoaded(true);
      return;
    }
    const handleLoad = () => {
      setLoaded(true);
    };
    const script = createStripoScript();
    script.addEventListener("load", handleLoad);
    document.body.appendChild(script);
    return () => {
      script.removeEventListener("load", handleLoad);
    };
  }, []);

  return (
    <>
      <StripoView
        codeEditorButtonRef={codeEditorButtonRef}
        historyButtonRef={historyButtonRef}
        settingsVisible={settingsVisible}
        historyVisible={historyVisible}
        lgUp={lgUpInitial}
      />
      <MessageDialog
        open={messageOpen}
        onClose={handleMessageClose}
        message={errorMessage}
      />
    </>
  );
}

export default React.memo(React.forwardRef(Stripo));
