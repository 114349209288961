import * as React from "react";
import { Box, Typography } from "@mui/material";
import { fetchApi } from "@tiny/utils";
import { ApiError, SenderNotConfirmedError } from "@tiny/utils/errors";
import SenderDetailsConfirmationDialogContent from "@/components/SenderDetailsConfirmationDialogContent";
import SuccessDialogContent from "@/routes/Campaigns/Calendar/Table/SuccessDialogContent";
import { useDialogInvokers, useScreenLoaderInvokers } from "@tiny/lib";
import { useRevalidator } from "react-router-dom";
import { AutomationProps } from "./types";
import AutomationFooter from "./AutomationFooter";
import AutomationHeader from "./AutomationHeader";

function Automation(props: AutomationProps) {
  const {
    id,
    active = false,
    name,
    description,
    infoDetails,
    emailTemplate,
    status,
    comingSoon,
    editable,
    image,
  } = props;

  const { requestMediumDialog } = useDialogInvokers();
  const revalidator = useRevalidator();
  const { showScreenLoader, hideScreenLoader } = useScreenLoaderInvokers();
  const { requestSmallDialog, exitDialog } = useDialogInvokers();

  React.useEffect(() => {
    if (revalidator.state === "idle") {
      hideScreenLoader();
    }
  }, [revalidator.state]);

  const onChangeActivation = (isActive: boolean) => {
    showScreenLoader();
    fetchApi<{
      actionRequired: boolean;
      enabled: boolean;
      requiredReasons: string[];
    }>(`/automationservice/automation/${id}/enabled/${isActive.toString()}`, {
      method: "put",
    })
      .then((resp) => {
        if (resp.actionRequired) {
          hideScreenLoader();
          requestSmallDialog({
            contentProps: {
              dividers: false,
              sx: {
                border: "none",
              },
              children: (
                <SuccessDialogContent
                  title="Action Required!"
                  description={resp?.requiredReasons
                    ?.map((reason) => `${reason}\n`)
                    ?.join()}
                  onClickHandler={() => {
                    exitDialog();
                  }}
                />
              ),
            },
          });
        } else {
          revalidator.revalidate();
        }
      })
      .catch((error: ApiError) => {
        if (error instanceof SenderNotConfirmedError) {
          requestMediumDialog({
            titleProps: {},
            contentProps: {
              dividers: false,
              sx: {
                border: "none",
              },
              children: <SenderDetailsConfirmationDialogContent />,
            },
          });
        }
        hideScreenLoader();
      });
  };

  return (
    <Box
      id={`automation-${id}`}
      display="flex"
      flexDirection="column"
      sx={{
        bgcolor: active ? "common.white" : "grey.20",
        boxSizing: "border-box",
        p: 5,
        borderWidth: "0.5px",
        borderStyle: "solid",
        borderColor: "grey.70",
        borderRadius: 2,
        height: "100%",
        position: "relative",
      }}
      className="automation-card"
    >
      <AutomationHeader
        comingSoon={comingSoon}
        active={active}
        name={name}
        infoDetails={infoDetails}
        status={status}
        id={id}
        editable={editable}
        image={image}
      />
      <Typography
        variant="p2"
        color="grey.80"
        mt={{ xs: 1, md: 1 }}
        mb={4}
        flex="1"
        className="automation-description"
      >
        {description}
      </Typography>
      <AutomationFooter
        id={id}
        emailTemplate={emailTemplate}
        onChangeActivation={onChangeActivation}
        active={active}
        status={status}
        comingSoon={comingSoon}
        editable={editable}
        automationName={name}
      />
    </Box>
  );
}

export default Automation;
