import { InitStripoOptions } from "./Stripo.types";

export function createStripoScript(): HTMLScriptElement {
  const script = document.createElement("script");
  script.id = "UiEditorScript";
  script.type = "module";
  script.src =
    "https://plugins.stripo.email/resources/uieditor/latest/UIEditor.js";
  script.async = true;
  return script;
}

export function initStripo(options: InitStripoOptions): void {
  const {
    accountId,
    css,
    emailId,
    html,
    onTemplateLoaded,
    onCodeEditorVisibilityChanged,
    onTemplateChange,
    onHistoryHide,
    onHistoryLoad,
    getAuthToken,
    username,
    viewOnly,
    onNotificationError,
    codeEditorButtonSelector,
  } = options;
  const metadata = {
    emailId: emailId,
    username: username,
    accountId: accountId,
  };
  const initOptions: any = {
    metadata: metadata,
    codeEditorButtonSelector,
    css,
    onTokenRefreshRequest: function (callback: (token: string) => void) {
      getAuthToken().then((token) => token && callback(token));
    },
    html,
    locale: "en",
    onTemplateLoaded: () => {
      onTemplateChange?.();
      onTemplateLoaded?.();
    },
    onCodeEditorVisibilityChanged,
    previewId: "stripoPreviewContainer",
    settingsId: "stripoSettingsContainer",
    onSaveCompleted: function () {
      onTemplateChange?.();
    },
    versionHistoryButtonSelector: "versionHistoryButtonSelector",
    forceRecreate: true,
    notifications: {
      loader: (_: string, origin: string) => {
        if (origin === "changeHistoryLoader") {
          onHistoryLoad?.();
        }
      },
      hide: (origin: string) => {
        if (origin === "changeHistoryLoader") {
          onHistoryHide?.();
        }
      },
      success: (message: string) => {
        if (message === "Email message has been restored successfully.") {
          onHistoryHide?.();
        }
      },
      error: (message: string) => {
        onNotificationError(message);
      },
    },
    customAppearanceMergetags: true,
    mergeTags: [
      {
        category: "TinyEmail",
        entries: [
          { label: "Email", value: "[CUSTOMER_EMAIL]" },
          { label: "First Name", value: "[CUSTOMER_FIRST_NAME]" },
          { label: "Last Name", value: "[CUSTOMER_LAST_NAME]" },
          { label: "Phone", value: "[CUSTOMER_PHONE]" },
          { label: "Birthday", value: "[CUSTOMER_BIRTHDAY]" },
          { label: "Company", value: "[CUSTOMER_COMPANY]" },
          { label: "Full Address", value: "[CUSTOMER_FULL_ADDRESS]" },
          { label: "Customer City", value: "[CUSTOMER_CITY]" },
          { label: "State/Province", value: "[CUSTOMER_STATE]" },
          { label: "Customer Country", value: "[CUSTOMER_COUNTRY]" },
          { label: "Postal Code", value: "[CUSTOMER_POSTAL_CODE]" },
          { label: "Campaign ID", value: "[CAMPAIGN_ID]" },
          { label: "Customer ID", value: "[CUSTOMER_ID]" },
          { label: "Sender ID", value: "[SENDER_DETAILS_ID]" },
          { label: "Currency", value: "[CUSTOMER_CURRENCY]" },
          { label: "Lifetime Value", value: "[CUSTOMER_LIFETIME_VALUE]" },
          {
            label: "Last Purchase Order",
            value: "[CUSTOMER_LAST_PURCHASE_ORDER]",
          },
          {
            label: "Last Purchase Price",
            value: "[CUSTOMER_LAST_PURCHASE_PRICE]",
          },
          { label: "Number of orders", value: "[CUSTOMER_NUMBER_OF_ORDERS]" },
          { label: "Status", value: "[CUSTOMER_STATUS]" },
          { label: "Created At", value: "[CUSTOMER_CREATED_AT]" },
        ],
      },
    ],
    pluginConfig: {
      customFontsEnabled: true,
    },
    editorFonts: {
      showDefaultStandardFonts: true,
      showDefaultNotStandardFonts: true,
    },
    enableXSSSecurity: true,
    // @ts-ignore
    addCustomFontTitle: "Add custom font title",
    // @ts-ignore
    panelPosition: "SETTINGS_BLOCKS",
  };

  const domContainer = document.querySelector("#stripoEditorContainer");
  if (!domContainer) {
    throw new Error("Stripo editor container not found");
  }

  if (
    // @ts-ignore
    typeof window.UIEditor === "undefined" ||
    // @ts-ignore
    typeof window.UIEditor.initEditor !== "function"
  ) {
    throw new Error("UIEditor not properly loaded");
  }
  // @ts-ignore
  window.UIEditor.initEditor(domContainer, initOptions);
}
