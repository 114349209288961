import React from "react";
import { useIsLargerThan } from "@tiny/theme/utils";

import stripoV2 from "../assets/StripoV2.png";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { Star } from "@tiny/icons";

interface NewStripoEditorDialogProps {
  open: boolean;
  onClose: () => void;
  onTryNew: () => void;
  onRemindLater: () => void;
}

function NewStripoEditorDialog({
  open,
  onClose,
  onTryNew,
  onRemindLater,
}: NewStripoEditorDialogProps): React.ReactElement {
  const mdUp = useIsLargerThan("md");

  const features: string[] = [
    "Loads Faster",
    "Improved UX",
    "New Blocks and Options Panels",
    "Modules Bulk Actions",
    "More Settings and Style Options",
  ];

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle>
        <Typography
          component="span"
          fontWeight="normal"
          variant="h3"
          color="grey"
        >
          Try the New Version of Our Email Editor (Beta)
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={mdUp ? 4 : 2}>
          <Grid item xs={12} md={6} mt={4}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <img
                src={stripoV2}
                alt="New Stripo Editor"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              component="div"
              variant="label1"
              color="grey"
              fontWeight="normal"
              my={4}
            >
              Switch back anytime
            </Typography>
            <Divider sx={{ mb: 4 }} />
            {features.map((feature, index) => (
              <Typography
                component="div"
                key={index}
                variant="label1"
                color="grey"
                fontWeight="normal"
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    lineHeight: "30px",
                  }}
                >
                  <Star
                    style={{
                      color: "#FFD700",
                      marginRight: "8px",
                      fontSize: "18px",
                    }}
                  />
                  {feature}
                </div>
              </Typography>
            ))}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={onTryNew}
          style={{ marginRight: "10px" }}
        >
          Try New
        </Button>
        <Button variant="outlined" onClick={onRemindLater}>
          Remind me Later
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default NewStripoEditorDialog;
