import React, { useCallback } from "react";
import { forwardRef, useState, useEffect } from "react";
import { StripoInstance, StripoProps } from "./Stripo.types";
import { useStripoContext } from "./StripoContext";
import StripoV1 from "./StripoV1/Stripo";
import StripoV2 from "./StripoV2/Stripo";
import NewStripoEditorDialog from "./Dialogs/NewStripoEditorDialog";

const getStripoComponent = (version: string) => {
  switch (version) {
    case "v2":
      //@ts-ignore
      return StripoV2.default || StripoV2;
    case "v1":
    default:
      //@ts-ignore
      return StripoV1.default || StripoV1;
  }
};

const Stripo = forwardRef<StripoInstance, StripoProps>((props, ref) => {
  const { version, switchVersion, templateState, updateTemplateState } =
    useStripoContext();

  const [showDialog, setShowDialog] = useState(false);

  const StripoComponent = getStripoComponent(version || "v1");

  useEffect(() => {
    if (props.html && props.css) {
      updateTemplateState(props.html, props.css);
    }
  }, [props.html, props.css, updateTemplateState]);

  const handleTemplateChange = useCallback(() => {
    props.onTemplateChange?.();
    if (ref && "current" in ref && ref.current) {
      ref.current.getTemplate((html: string, css: string) => {
        updateTemplateState(html, css);
      });
    }
  }, [props, ref, updateTemplateState]);

  useEffect(() => {
    if (version === "v1" && !localStorage.getItem("stripoV2Dialog")) {
      setShowDialog(true);
    }
    console.log(showDialog);
  }, [version]);

  const handleTryNew = () => {
    setShowDialog(false);
    localStorage.setItem("stripoV2Dialog", "true");
    switchVersion && switchVersion("v2");
  };

  const handleRemindLater = () => {
    setShowDialog(false);
    localStorage.setItem("stripoV2Dialog", "true");
  };

  if (!StripoComponent) {
    return null;
  }

  return (
    <>
      <StripoComponent
        {...props}
        ref={ref}
        html={templateState?.html || ""}
        css={templateState?.css || ""}
        onTemplateChange={handleTemplateChange}
      />
      {version === "v1" && (
        <NewStripoEditorDialog
          open={showDialog}
          onClose={() => setShowDialog(false)}
          onTryNew={handleTryNew}
          onRemindLater={handleRemindLater}
        />
      )}
    </>
  );
});

export default Stripo;

export { Stripo };
export * from "./StripoContext";
export * from "./stripoEventHandlers";
export * from "./StripoSwitcher";
